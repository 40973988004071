import * as React from "react";
import {
  AuthCredentials,
  getAuthCredentialsFromCode,
  refreshAuthCredentialsFromToken,
} from ".";
import * as qs from "query-string";
import * as Cookies from "js-cookie";
import { useHistory } from "react-router-dom";

export interface AuthContextValue {
  creds: AuthCredentials | null;
  status: AuthStatus;
}

export enum AuthStatus {
  Unknown = "Unknown",
  Authenticated = "Authenticated",
  Unauthenticated = "Unauthenticated",
}

enum AuthActionType {
  SetCredentials = "SetCredentials",
  Logout = "Logout",
}

interface ActionSetCredentials {
  type: AuthActionType.SetCredentials;
  creds: AuthCredentials;
}

interface ActionLogout {
  type: AuthActionType.Logout;
}

type AuthAction = ActionSetCredentials | ActionLogout;

export const authContextReducer = (
  state: AuthContextValue,
  action: AuthAction
) => {
  switch (action.type) {
    case AuthActionType.SetCredentials:
      return {
        ...state,
        creds: action.creds,
        status: AuthStatus.Authenticated,
      };
    case AuthActionType.Logout:
      return { creds: null, status: AuthStatus.Unauthenticated };
    default:
      return state;
  }
};

export const authContext = React.createContext<AuthContextValue>({
  status: AuthStatus.Unknown,
  creds: null,
});

export const AuthContextManager: React.FC = ({ children }) => {
  const [value, dispatch] = React.useReducer(authContextReducer, {
    status: AuthStatus.Unknown,
    creds: null,
  });

  /**
   * History used to clear ?code= query param following sign in/up
   */
  const { replace } = useHistory();

  /**
   * Called when authentication credentials are fetched/refreshed.
   */
  const onCredentials = React.useCallback((creds: AuthCredentials) => {
    console.log("Authenticated", creds);

    // If the credential fetching was the result of a refresh,
    // the existing refresh token will not be returned in the
    // credentials. Thus, we grab it again from Cookies and add
    // it back to the credentials for the next time we refresh.
    // If the credentials include the refresh token, then set
    // it to Cookies, overriding any existing token since it's
    // no longer valid.
    if (creds.refreshToken) {
      Cookies.set("refresh_token", creds.refreshToken, {
        secure: true,
      });
    } else {
      creds.refreshToken = Cookies.get("refresh_token");
    }

    dispatch({ type: AuthActionType.SetCredentials, creds });
  }, []);

  /**
   * Called when authentication credentials fetching fails,
   * logging out the user.
   */
  const onCredentialsError = React.useCallback((error: Error) => {
    console.error("Authentication Failed", error);
    dispatch({ type: AuthActionType.Logout });
  }, []);

  const refreshToken = value.creds?.refreshToken;

  /**
   * Initialize Auth:
   * - if ?code= query param is found on window.location.search,
   *   this takes precedence, and authentication is attempted with this code.
   * - if a `refresh_token` cookie is available, the token is refreshed.
   * - if either of these fail, the user is considered "logged out"
   */
  React.useEffect(() => {
    console.log("auth token refreshing/setting run");
    const { code } = qs.parse(window.location.search);

    /**
     * If there's a ?code= query param, this indicates a redirect
     * from AWS cognito. Delete auth cookies and grab new ones from
     * the code validation response.
     */
    if (code) {
      getAuthCredentialsFromCode(
        code as string,
        onCredentials,
        onCredentialsError
      );
      replace(window.location.pathname);
      return;
    }

    /**
     * If there's a refresh_token cookie, let's attempt to refresh
     * and grab new credentials here.
     */
    const refreshToken = Cookies.get("refresh_token");
    if (refreshToken) {
      refreshAuthCredentialsFromToken(
        refreshToken,
        onCredentials,
        onCredentialsError
      );
      return;
    }

    /**
     * Finally, if there's no auth initiation options, consider
     * the user logged out.
     */
    dispatch({ type: AuthActionType.Logout });
  }, [onCredentials, onCredentialsError, replace]);

  /**
   * Refresh token interval
   * - attempt to refresh the token every 20 hours
   *   (god forbid someone keeps the site open that long)
   */
  React.useEffect(() => {
    let interval = 0;

    if (refreshToken) {
      interval = window.setInterval(() => {
        refreshAuthCredentialsFromToken(
          refreshToken,
          onCredentials,
          onCredentialsError
        );
      }, 20 * 60 * 60 * 1000);
    }

    return () => {
      if (interval) {
        window.clearInterval(interval);
      }
    };
  }, [onCredentials, onCredentialsError, refreshToken]);

  return (
    <authContext.Provider value={value}>
      {children || null}
    </authContext.Provider>
  );
};
