import { AwsClient } from "aws4fetch";
import { getAdminCredentials } from "../auth";
import {
  AdminPortalInfo,
  AdminStats,
  Game,
  GameWinners,
  GuestPick,
  LeaderboardRow,
  LeaderboardsAPIResponse,
  Schedule,
  Team,
} from "./models";

export const API_URI =
  "https://d5u9grd8u6.execute-api.us-west-2.amazonaws.com/prod";

interface TeamsResponse {
  teams: Team[] | null;
}

interface ScheduleResponse {
  schedule: Schedule | null;
}

interface AdminPortalInfoResponse {
  schedule: Schedule | null;
  totalPicks: number;
}

interface CreateScheduleResponseSuccess {
  success: true;
}

interface CreateScheduleResponseFailure {
  success: false;
  reason: string;
}

interface SuccessResponse {
  success: boolean;
}

interface CompleteWeekRequest {
  scheduleID: string;
}

type CreateScheduleResponse =
  | CreateScheduleResponseSuccess
  | CreateScheduleResponseFailure;

export const getTeams = async (accessToken: string): Promise<Team[] | null> => {
  try {
    const adminCreds = await getAdminCredentials(accessToken);
    const aws = new AwsClient({
      accessKeyId: adminCreds.AccessKeyId!,
      secretAccessKey: adminCreds.SecretKey!,
      sessionToken: adminCreds.SessionToken!,
    });

    const response = await aws.fetch(`${API_URI}/teams`);
    const json = (await response.json()) as TeamsResponse;
    return json.teams;
  } catch (err) {
    return null;
  }
};

export const getSchedule = async (
  accessToken?: string
): Promise<Schedule | null> => {
  try {
    const headers = accessToken ? { Authorization: accessToken } : undefined;
    const response = await fetch(`${API_URI}/schedule`, { headers });
    const json = (await response.json()) as ScheduleResponse;
    return json.schedule;
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const createSchedule = async (
  games: Game[],
  accessToken: string
): Promise<CreateScheduleResponse | null> => {
  try {
    const adminCreds = await getAdminCredentials(accessToken);
    const aws = new AwsClient({
      accessKeyId: adminCreds.AccessKeyId!,
      secretAccessKey: adminCreds.SecretKey!,
      sessionToken: adminCreds.SessionToken!,
    });

    const response = await aws.fetch(`${API_URI}/schedule`, {
      method: "POST",
      body: JSON.stringify(games),
    });

    const json = (await response.json()) as CreateScheduleResponse;
    return json;
  } catch (err) {
    return null;
  }
};

export const createGuestPick = async (pick: GuestPick, accessToken: string) => {
  try {
    console.log("createGuestPick", pick);

    const response = await fetch(`${API_URI}/picks`, {
      method: "POST",
      body: JSON.stringify(pick),
      headers: {
        Authorization: accessToken,
      },
    });

    const json = (await response.json()) as CreateScheduleResponse;
    return json;
  } catch (err) {
    return null;
  }
};

export const updateGuestPick = async (pick: GuestPick, accessToken: string) => {
  try {
    const response = await fetch(`${API_URI}/update`, {
      method: "POST",
      body: JSON.stringify(pick),
      headers: {
        Authorization: accessToken,
      },
    });

    const json = (await response.json()) as SuccessResponse;
    return json;
  } catch (err) {
    return null;
  }
};

export const pickGameWinners = async (
  winners: GameWinners,
  accessToken: string
): Promise<SuccessResponse | null> => {
  try {
    const adminCreds = await getAdminCredentials(accessToken);
    const aws = new AwsClient({
      accessKeyId: adminCreds.AccessKeyId!,
      secretAccessKey: adminCreds.SecretKey!,
      sessionToken: adminCreds.SessionToken!,
    });

    const response = await aws.fetch(`${API_URI}/winners`, {
      method: "POST",
      body: JSON.stringify(winners),
    });

    const json = (await response.json()) as SuccessResponse;
    return json;
  } catch (err) {
    return null;
  }
};

export const completeWeek = async (
  scheduleID: string,
  accessToken: string
): Promise<SuccessResponse | null> => {
  try {
    const adminCreds = await getAdminCredentials(accessToken);
    const aws = new AwsClient({
      accessKeyId: adminCreds.AccessKeyId!,
      secretAccessKey: adminCreds.SecretKey!,
      sessionToken: adminCreds.SessionToken!,
    });

    const response = await aws.fetch(`${API_URI}/complete`, {
      method: "POST",
      body: JSON.stringify({ scheduleID }),
    });

    const json = (await response.json()) as SuccessResponse;
    return json;
  } catch (err) {
    return null;
  }
};

export const lockWeek = async (
  accessToken: string
): Promise<SuccessResponse | null> => {
  try {
    const adminCreds = await getAdminCredentials(accessToken);
    const aws = new AwsClient({
      accessKeyId: adminCreds.AccessKeyId!,
      secretAccessKey: adminCreds.SecretKey!,
      sessionToken: adminCreds.SessionToken!,
    });

    const response = await aws.fetch(`${API_URI}/lock`, {
      method: "POST",
      body: "{}",
    });

    const json = (await response.json()) as SuccessResponse;
    return json;
  } catch (err) {
    return null;
  }
};

export const getAdminPortalInfo = async (
  accessToken: string
): Promise<AdminPortalInfo> => {
  try {
    const adminCreds = await getAdminCredentials(accessToken);
    const aws = new AwsClient({
      accessKeyId: adminCreds.AccessKeyId!,
      secretAccessKey: adminCreds.SecretKey!,
      sessionToken: adminCreds.SessionToken!,
    });

    const response = await aws.fetch(`${API_URI}/admin`, {
      method: "GET",
    });

    const json = (await response.json()) as AdminPortalInfo;
    return json;
  } catch (err) {
    return {
      schedule: null,
      previousSchedules: [],
      totalPicksForCurrentSchedule: 0,
      allWinningPicks: [],
    };
  }
};

export const getAdminStats = async (
  accessToken: string
): Promise<AdminStats | null> => {
  try {
    const adminCreds = await getAdminCredentials(accessToken);
    const aws = new AwsClient({
      accessKeyId: adminCreds.AccessKeyId!,
      secretAccessKey: adminCreds.SecretKey!,
      sessionToken: adminCreds.SessionToken!,
    });

    const response = await aws.fetch(`${API_URI}/stats`, {
      method: "GET",
    });

    const json = (await response.json()) as AdminStats;
    return json;
  } catch (err) {
    return null;
  }
};

export const getLeaderboardAsync = async (
  weekly: boolean,
  onComplete: (leaderboard: LeaderboardRow[] | null) => void,
  accessToken?: string
): Promise<boolean> => {
  try {
    const headers = accessToken ? { Authorization: accessToken } : undefined;
    const response = await fetch(
      `${API_URI}/${weekly ? "weekly" : "overall"}_leaderboard`,
      { headers }
    );
    const json = (await response.json()) as {
      leaderboard: LeaderboardRow[] | null;
    };
    onComplete(json.leaderboard);

    return true;
  } catch (err) {
    return false;
  }
};

export const getLeaderboardsAsync = async (
  onComplete: (leaderboard: LeaderboardsAPIResponse | null) => void,
  accessToken?: string
): Promise<boolean> => {
  try {
    const headers = accessToken ? { Authorization: accessToken } : undefined;
    const response = await fetch(`${API_URI}/leaderboards`, { headers });
    const json = (await response.json()) as LeaderboardsAPIResponse;
    onComplete(json);

    return true;
  } catch (err) {
    return false;
  }
};
