import { Alert, Button, Collapse, Spin } from "antd";
import * as React from "react";
import { useAdminStats } from "../../api/use-admin-stats";
import { authContext, AuthStatus } from "../../auth/context";
import { AuthButton } from "../../ui/auth-header/auth-button";
import { PageHeader } from "../../ui/page-header";
import "./index.css";
import { StatsPanel } from "./stats-panel";

export const StatsPage: React.FC = () => {
  const {
    schedules,
    picksForSchedule,
    winningPicks,
    loading,
    error,
  } = useAdminStats();
  const { status, creds } = React.useContext(authContext);

  if (loading || status === AuthStatus.Unknown) {
    return (
      <div className="stats-page">
        <Spin size={"large"} />
      </div>
    );
  }

  // No creds, let the user sign in here
  if (!creds) {
    return (
      <div className="stats-page">
        <div className="stats-page__cta">
          <p>
            Doesn't look like you're signed-in. Sign in first to admin the site.
          </p>
        </div>
        <AuthButton />
      </div>
    );
  }

  // If the user isn't an admin, give them a button to go home
  if (!creds.isAdmin || !creds.accessToken) {
    return (
      <div className="stats-page">
        <div className="stats-page__cta">
          <p>
            Doesn't look like you have admin priveleges. Try picking winners
            instead ;)
          </p>
        </div>
        <Button type="primary" href="/">
          Home
        </Button>
      </div>
    );
  }

  if (error) {
    return (
      <div className="stats-page">
        <Alert
          message={"Failed to load stats."}
          description={"Might be a network glitch. Refresh and try again."}
          type="error"
        />
      </div>
    );
  }

  return (
    <div className="stats-page">
      <PageHeader title="Weekly Stats and Winners">
        Take a look at the current week's stats or see any previous week's stats
        and winners. Use the download buttons to download/email yourself a
        spreadsheet with winner information.
      </PageHeader>
      <Collapse
        className="stats-page__collapse"
        defaultActiveKey={schedules[0] ? schedules[0].id : "none"}
        // ghost
      >
        {schedules.map((schedule, i) => (
          <Collapse.Panel
            header={`Week ${schedules.length - i} - ${new Date(
              schedule.createdAt
            ).toDateString()}`}
            key={schedule.id}
          >
            <StatsPanel
              schedule={schedule}
              picksForSchedule={picksForSchedule[schedule.id] || 0}
              winningPicks={winningPicks[schedule.id] || null}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
      <div className="stats-page__web-share">
        Note: to be able to download/share files from iOS, you need to enable
        something in your settings. Go to Settings. Go to Safari. Scroll down to
        the bottom and click Advanced. Click Experimental Features at the
        bottom. Scroll down to the bottom and check the box for Web Share API
        Level 2. This allows iOS to share the winners spreadsheet files.
      </div>
    </div>
  );
};
