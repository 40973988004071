import { Button, Spin } from "antd";
import * as React from "react";
import { useSchedule } from "../../api/use-schedule";
import { authContext, AuthStatus } from "../../auth/context";
import { AuthButton } from "../../ui/auth-header/auth-button";
import { CalculateWinners } from "./calculate-winners";
import { CreateSchedule } from "./create-schedule";
import "./index.css";
import { LockWeek } from "./lock-week";
import { PickWinners } from "./pick-winners";

export const AdminPage: React.FC = () => {
  const { status, creds } = React.useContext(authContext);
  const {
    schedule,
    loading,
    updateWithGameWinners,
    markWeekCompleted,
    markWeekLocked,
  } = useSchedule();

  // If we're loading, show the spinner
  if (loading || status === AuthStatus.Unknown) {
    return (
      <div className="admin-page">
        <Spin size={"large"} />
      </div>
    );
  }

  // No creds, let the user sign in here
  if (!creds) {
    return (
      <div className="admin-page">
        <div className="admin-page__cta">
          <p>
            Doesn't look like you're signed-in. Sign in first to admin the site.
          </p>
        </div>
        <AuthButton />
      </div>
    );
  }

  // If the user isn't an admin, give them a button to go home
  if (!creds.isAdmin || !creds.accessToken) {
    return (
      <div className="admin-page">
        <div className="admin-page__cta">
          <p>
            Doesn't look like you have admin priveleges. Try picking winners
            instead ;)
          </p>
        </div>
        <Button type="primary" href="/">
          Home
        </Button>
      </div>
    );
  }

  const hasSavedAllWinners = !schedule?.games.find((g) => !g.winningTeamID);

  /**
   * What can an admin do:
   * - schedule, unlocked: nothing!
   * - schedule, locked: complete the week by entering winners
   * - no schedule: start a new week
   */
  if (!schedule || schedule.completed) {
    return <CreateSchedule accessToken={creds.accessToken} />;
  } else if (schedule && !schedule.locked) {
    return (
      <LockWeek
        accessToken={creds.accessToken}
        markWeekLocked={markWeekLocked}
      />
    );
  } else if (schedule && !hasSavedAllWinners) {
    return (
      <PickWinners
        schedule={schedule}
        accessToken={creds.accessToken}
        updateWithGameWinners={updateWithGameWinners}
      />
    );
  } else if (schedule && hasSavedAllWinners) {
    return (
      <CalculateWinners
        schedule={schedule}
        accessToken={creds.accessToken}
        markWeekCompleted={markWeekCompleted}
      />
    );
  }

  return <div>Schedule is currently active!</div>;
};
