import { Alert, Button, Image, Modal, Spin } from "antd";
import * as React from "react";
import { createGuestPick, updateGuestPick } from "../../api";
import { GuestPick } from "../../api/models";
import { useSchedule } from "../../api/use-schedule";
import { authContext, AuthStatus } from "../../auth/context";
import { AuthButton } from "../../ui/auth-header/auth-button";
import { PageHeader, PageHeaderIcon } from "../../ui/page-header";
import { SelectionMode, Ticket } from "../../ui/ticket";
import moesLogo from "../../assets/moes-logo.svg";
import "./index.css";
import { LiveWeekScoreboard } from "./live-week-scoreboard";
import { Link } from "react-router-dom";

export const PickPage: React.FC = () => {
  const [isPicking, setIsPicking] = React.useState(false);
  const [picksSubmitSuccessfully, setPicksSubmitSuccessfully] = React.useState(
    false
  );
  const [hasError, setHasError] = React.useState(false);
  const [currentPicks, setCurrentPicks] = React.useState<
    Record<string, string>
  >({});
  const {
    schedule,
    loading: loadingSchedule,
    updateWithGuestPick,
    refresh,
  } = useSchedule();
  const { status: authStatus, creds } = React.useContext(authContext);

  const openModal = () => {
    Modal.info({
      title: "Thanks for picking!",
      content: (
        <div className="header-modal">
          <p>
            {
              "Now that you've made your picks, swing on by Moe's to watch the games and enjoy a Bushwhacker, or one of our many delicious food or drink specials. Responsibly, of course."
            }
          </p>
          <a
            href="https://www.moesoriginalbbq.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="pick-page__logo"
          >
            <Image height="160px" src={moesLogo} />
          </a>
        </div>
      ),
      cancelButtonProps: {
        hidden: true,
      },
      okText: "Close",
      icon: null,
    });
  };

  if (authStatus === AuthStatus.Unknown || loadingSchedule) {
    return (
      <div className="pick-page">
        <Spin size={"large"} />
      </div>
    );
  }

  const canPick = schedule && !schedule.locked && !schedule.completed;
  const hasAlreadyPickedThisWeek = schedule?.games[0].guestPickTeamID;

  const live = (
    <>
      {authStatus === AuthStatus.Authenticated
        ? hasAlreadyPickedThisWeek
          ? "This week is live! Come back on Saturday to see how your picks stack up to Nick's. You can update your picks below up until the games are locked on Saturday."
          : "This week is live! Let's get pickin'. Make sure you think about the spread!"
        : "This week is live! Sign in to submit your picks."}
    </>
  );

  const locked = (
    <>
      {hasAlreadyPickedThisWeek
        ? "This week is locked! Check out Nick's picks and see how your picks stack up."
        : "This week is locked! Try your luck next week. The schedule resets every Monday or Tuesday."}
      <div style={{ marginTop: "1rem" }}>
        <LiveWeekScoreboard schedule={schedule} />
      </div>
    </>
  );

  const noCurrentSchedule = (
    <div>
      Nick hasn't picked this week's games yet. Check back on Monday for the new
      schedule.
      <div style={{ margin: "2rem 0" }}>
        Are you better than Nick?
        <div>
          <Link to="/leaderboard">
            <Button type="link">{"Check the leaderboards today."}</Button>
          </Link>
        </div>
      </div>
    </div>
  );

  const pickButton = (
    <div className="pick-page__submit-picks">
      <Button
        type="primary"
        size={"large"}
        disabled={
          !schedule ||
          authStatus !== AuthStatus.Authenticated ||
          isPicking ||
          picksSubmitSuccessfully ||
          schedule.games.length !== Object.keys(currentPicks).length
        }
        onClick={() => {
          setIsPicking(true);
          submitPicks({
            accessToken: creds?.accessToken,
            picks: currentPicks,
            scheduleID: schedule?.id || "",
            onSuccess: (guestPick) => {
              setHasError(false);
              setPicksSubmitSuccessfully(true);
              updateWithGuestPick(guestPick);
              setIsPicking(false);
              openModal();
            },
            onFailure: () => {
              setHasError(true);
              setPicksSubmitSuccessfully(false);
              setIsPicking(false);
            },
          });
        }}
      >
        {"Submit Picks!"}
      </Button>
    </div>
  );

  const updatePicksButton = (
    <div className="pick-page__submit-picks">
      <Button
        type="primary"
        size={"large"}
        disabled={
          !schedule ||
          authStatus !== AuthStatus.Authenticated ||
          isPicking ||
          !Object.keys(currentPicks).length
        }
        onClick={() => {
          setIsPicking(true);
          updatePicks({
            accessToken: creds?.accessToken,
            picks: currentPicks,
            scheduleID: schedule?.id || "",
            onSuccess: (guestPick) => {
              setHasError(false);
              setPicksSubmitSuccessfully(true);
              updateWithGuestPick(guestPick);
              setIsPicking(false);
              setCurrentPicks({});
              refresh();
              openModal();
            },
            onFailure: () => {
              setHasError(true);
              setPicksSubmitSuccessfully(false);
              setIsPicking(false);
            },
          });
        }}
      >
        {"Update Picks!"}
      </Button>
    </div>
  );

  return (
    <div className="pick-page">
      <PageHeader
        title={"Weekly Games"}
        icon={!canPick ? PageHeaderIcon.Locked : undefined}
      >
        <>
          {(!schedule || schedule.completed) && noCurrentSchedule}
          {schedule && schedule.locked && locked}
          {schedule && !schedule.locked && live}
          {authStatus === AuthStatus.Unauthenticated && (
            <div className="pick-page__header-auth">
              <AuthButton />
            </div>
          )}
        </>
      </PageHeader>
      <div className="pick-page__tickets">
        {schedule &&
          schedule.games.map((game) => (
            <div className="pick-page__ticket" key={game.id}>
              <Ticket
                game={game}
                selectionMode={
                  !schedule.locked && !schedule.completed
                    ? SelectionMode.Guest
                    : SelectionMode.None
                }
                enabledSelection={authStatus === AuthStatus.Unauthenticated}
                onSelect={(gameID, teamID) =>
                  setCurrentPicks({ ...currentPicks, [gameID]: teamID })
                }
                onUnselect={() => null}
              />
            </div>
          ))}
      </div>
      {hasError && !isPicking && (
        <div className="pick-page__error">
          <Alert
            message={"Failed to submit picks!"}
            description={"Refresh and try again."}
            type="error"
          />
        </div>
      )}
      {canPick && !hasAlreadyPickedThisWeek && (
        <div className="pick-page__submit-picks">{pickButton}</div>
      )}
      {canPick && hasAlreadyPickedThisWeek && (
        <div className="pick-page__submit-picks">{updatePicksButton}</div>
      )}
    </div>
  );
};

interface SubmitPicksOpts {
  scheduleID: string;
  picks: Record<string, string>;
  accessToken?: string;
  onSuccess: (guestPick: GuestPick) => void;
  onFailure: () => void;
}

const submitPicks = async (opts: SubmitPicksOpts) => {
  if (!opts.accessToken) {
    return;
  }

  const guestPick: GuestPick = {
    scheduleID: opts.scheduleID,
    games: opts.picks,
  };

  const resp = await createGuestPick(guestPick, opts.accessToken);

  if (resp?.success) {
    opts.onSuccess(guestPick);
  } else {
    opts.onFailure();
  }
};

const updatePicks = async (opts: SubmitPicksOpts) => {
  if (!opts.accessToken) {
    return;
  }

  const guestPick: GuestPick = {
    scheduleID: opts.scheduleID,
    games: opts.picks,
  };

  const resp = await updateGuestPick(guestPick, opts.accessToken);

  if (resp?.success) {
    opts.onSuccess(guestPick);
  } else {
    opts.onFailure();
  }
};
