import { useCallback, useContext, useEffect, useMemo, useReducer } from "react";
import { getSchedule } from ".";
import { authContext, AuthStatus } from "../auth/context";
import { Game, GameWinners, GuestPick, Schedule } from "./models";

interface UseScheduleState {
  schedule: Schedule | null;
  loading: boolean;
}

interface UseScheduleLoadingAction {
  type: "loaded";
  schedule: Schedule | null;
}

interface UseScheduleUpdateAction {
  type: "updated";
  schedule: Schedule;
}

interface UseScheduleRefreshAction {
  type: "refresh";
}

type Action =
  | UseScheduleLoadingAction
  | UseScheduleUpdateAction
  | UseScheduleRefreshAction;

export const useSchedule = () => {
  const { creds, status } = useContext(authContext);
  const accessToken = creds?.accessToken;

  const [state, dispatch] = useReducer(
    (state: UseScheduleState, action: Action) => {
      switch (action.type) {
        case "loaded":
        case "updated":
          return {
            loading: false,
            schedule: action.schedule,
          };
        case "refresh":
          return { ...state, loading: true };
        default:
          return state;
      }
    },
    {
      loading: true,
      schedule: null,
    }
  );

  useEffect(() => {
    if (status === AuthStatus.Unknown) {
      return;
    }

    getScheduleAsync(
      (schedule) => dispatch({ type: "loaded", schedule }),
      accessToken
    );
  }, [accessToken, status]);

  const updateWithGameWinners = useCallback(
    (winners: GameWinners) => {
      if (!state.schedule) {
        return;
      }

      const next = { ...state.schedule };
      next.games = next.games.reduce((allGames, game) => {
        const nextGame: Game = {
          ...game,
          winningTeamID: winners.games[game.id] ?? game.winningTeamID,
        };

        return [...allGames, nextGame];
      }, [] as Game[]);

      dispatch({ type: "updated", schedule: next });
    },
    [state.schedule]
  );

  const updateWithGuestPick = useCallback(
    (pick: GuestPick) => {
      if (!state.schedule) {
        return;
      }

      const next = { ...state.schedule };
      next.games = next.games.reduce((allGames, game) => {
        const nextGame: Game = {
          ...game,
          guestPickTeamID: pick.games[game.id]
            ? pick.games[game.id]
            : game.guestPickTeamID,
        };

        return [...allGames, nextGame];
      }, [] as Game[]);

      dispatch({ type: "updated", schedule: next });
    },
    [state.schedule]
  );

  const markWeekCompleted = useCallback(() => {
    if (!state.schedule) {
      return;
    }

    const next: Schedule = { ...state.schedule, completed: true, locked: true };
    dispatch({ type: "updated", schedule: next });
  }, [state.schedule]);

  const markWeekLocked = useCallback(() => {
    if (!state.schedule) {
      return;
    }

    const next: Schedule = { ...state.schedule, locked: true };
    dispatch({ type: "updated", schedule: next });
  }, [state.schedule]);

  const refresh = useCallback(() => {
    dispatch({ type: "refresh" });

    getScheduleAsync((schedule) => {
      if (schedule) {
        dispatch({ type: "loaded", schedule });
      }
    }, accessToken);
  }, [accessToken]);

  const value = useMemo(
    () => ({
      ...state,
      markWeekCompleted,
      markWeekLocked,
      refresh,
      updateWithGuestPick,
      updateWithGameWinners,
    }),
    [
      markWeekCompleted,
      markWeekLocked,
      refresh,
      state,
      updateWithGameWinners,
      updateWithGuestPick,
    ]
  );

  return value;
};

const getScheduleAsync = async (
  onLoad: (schedule: Schedule | null) => void,
  accessToken?: string
) => {
  const schedule = await getSchedule(accessToken);
  onLoad(schedule);
};
