import {
  Alert,
  Button,
  Checkbox,
  Divider,
  InputNumber,
  Select,
  Spin,
} from "antd";
import * as React from "react";
import { createSchedule } from "../../api";
import { Game, Team } from "../../api/models";
import { PageHeader } from "../../ui/page-header";
import { v4 as uuidv4 } from "uuid";
import "./create-schedule.css";
import { useTeams } from "../../api/use-teams";
import { CloseSquareFilled, WarningFilled } from "@ant-design/icons";

interface Props {
  accessToken: string;
}

interface GamesState {
  games: Array<{ id: string; game: Game | null }>;
}

interface NewGameAction {
  type: "new";
}

interface GamesUpdateAction {
  type: "update";
  game: Game;
}

interface RemoveAction {
  type: "remove";
  id: string;
}

export const CreateSchedule: React.FC<Props> = ({ accessToken }) => {
  const { cfbTeams, nflTeams, loading } = useTeams();
  const [isSaving, setIsSaving] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [readyToSave, setReadyToSave] = React.useState(false);

  const [gameState, dispatch] = React.useReducer(
    (
      state: GamesState,
      action: NewGameAction | GamesUpdateAction | RemoveAction
    ) => {
      switch (action.type) {
        case "new":
          return {
            games: [...state.games, { id: uuidv4(), game: null }],
          };
        case "update":
          return {
            games: state.games.reduce((allGames, g) => {
              if (g.id === action.game.id) {
                allGames.push({ id: action.game.id, game: action.game });
              } else {
                allGames.push(g);
              }

              return allGames;
            }, [] as GamesState["games"]),
          };
        case "remove":
          return {
            games: state.games.filter((g) => g.id !== action.id),
          };
        default:
          return state;
      }
    },
    getGamesStateFromStorage()
  );

  React.useEffect(() => {
    saveGamesStateToStorage(gameState);
  }, [gameState]);

  // All games have a filled game property
  const canSave =
    gameState.games.length > 0 && !gameState.games.find((g) => g.game === null);

  const createScheduleButton = (
    <div className="create-schedule__button">
      <Button
        type="primary"
        size={"large"}
        disabled={isSaving || !canSave || !readyToSave}
        onClick={() => {
          setIsSaving(true);
          saveScheduleAsync({
            accessToken,
            games: gameState.games.map((g) => g.game!),
            onSuccess: () => {
              setHasError(false);
              setIsSaving(false);
              clearGamesStateFromStorage();
              window.location.assign("/");
            },
            onFailure: () => {
              setHasError(true);
              setIsSaving(false);
            },
          });
        }}
      >
        {"Save Schedule!"}
      </Button>
    </div>
  );

  const addGameButton = (
    <div className="create-schedule__button">
      <Button
        type="primary"
        size={"large"}
        disabled={isSaving}
        onClick={() => dispatch({ type: "new" })}
      >
        {"Add Game"}
      </Button>
    </div>
  );

  const pageHeader = (
    <PageHeader title={"Admin - Create Weekly Schedule"}>
      <p style={{ marginBottom: "1rem" }}>
        {
          "It's time to get ready for another week! Use the form below to add games and Nick's picks to the schedule. Once you're done, click the Save Schedule button. After the schedule is saved, users will be able to pick the week's games."
        }
      </p>
    </PageHeader>
  );

  const onComplete = React.useCallback((game: Game) => {
    dispatch({ type: "update", game });
  }, []);

  if (loading) {
    return (
      <div className="create-schedule">
        {pageHeader}
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="create-schedule">
      {pageHeader}
      {cfbTeams &&
        nflTeams &&
        gameState.games.map((gameItem, i) => (
          <GameFormItem
            num={i + 1}
            game={gameItem.game}
            cfbTeams={cfbTeams}
            nflTeams={nflTeams}
            key={gameItem.id}
            id={gameItem.id}
            onComplete={onComplete}
            onRemove={(id) => dispatch({ type: "remove", id })}
          />
        ))}
      <Divider />
      {addGameButton}
      <Divider />
      {hasError && !isSaving && (
        <div className="create-schedule__error">
          <Alert
            message={"Failed to save schedule... maybe a network glitch."}
            description={"Refresh and try again."}
            type="error"
          />
        </div>
      )}
      <div className="create-schedule__ready">
        <Checkbox
          checked={readyToSave}
          onChange={() => setReadyToSave(!readyToSave)}
        >
          Ready to save?
        </Checkbox>
      </div>
      {createScheduleButton}
    </div>
  );
};

interface SaveScheduleOpts {
  games: Game[];
  accessToken: string;
  onSuccess: () => void;
  onFailure: () => void;
}

export const saveScheduleAsync = async (opts: SaveScheduleOpts) => {
  const resp = await createSchedule(opts.games, opts.accessToken);

  if (resp?.success) {
    opts.onSuccess();
  } else {
    opts.onFailure();
  }
};

interface GameFormItemProps {
  num: number;
  id: string;
  game: Game | null;
  cfbTeams: Team[];
  nflTeams: Team[];
  onComplete: (game: Game) => void;
  onRemove: (id: string) => void;
}

export const GameFormItem: React.FC<GameFormItemProps> = ({
  id,
  game,
  cfbTeams,
  nflTeams,
  onComplete,
  onRemove,
  num,
}) => {
  const [teamA, setTeamA] = React.useState<Team | null>(game?.teamA ?? null);
  const [teamB, setTeamB] = React.useState<Team | null>(game?.teamB ?? null);
  const [spreadA, setSpreadA] = React.useState<number | null>(
    game?.spread.a ?? null
  );
  const [spreadB, setSpreadB] = React.useState<number | null>(
    game?.spread.b ?? null
  );
  const [nicksPick, setNicksPick] = React.useState<string | null>(
    game?.nicksPickTeamID ?? null
  );

  React.useEffect(() => {
    if (
      teamA &&
      teamB &&
      spreadA &&
      spreadB &&
      nicksPick &&
      teamA.sport === teamB.sport
    ) {
      onComplete({
        id,
        teamA,
        teamB,
        spread: {
          a: spreadA,
          b: spreadB,
        },
        nicksPickTeamID: nicksPick,
      });
    }
  }, [teamA, teamB, spreadA, spreadB, nicksPick, onComplete, id]);

  const sameTeamError = teamA && teamB && teamA.id === teamB.id;
  const diffSportError = teamA && teamB && teamA.sport !== teamB.sport;
  const teamAExists = null;
  const teamBExists = null;

  const getTeamSelect = (
    defaultTeam: Team | null,
    onTeam: (team: Team) => void
  ) => (
    <Select
      showSearch
      style={{ width: 300 }}
      placeholder="Select team"
      optionFilterProp="children"
      defaultValue={defaultTeam?.name}
      onChange={(id) => {
        const foundTeam =
          cfbTeams.find((team) => team.id === id) ||
          nflTeams.find((team) => team.id === id);

        if (foundTeam) {
          onTeam(foundTeam);
        }
      }}
      filterOption={(input, option) => {
        console.log(option);
        if (!option?.children) {
          return false;
        }

        return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
    >
      <Select.OptGroup label="CFB">
        {cfbTeams.map((team) => (
          <Select.Option value={team.id} key={team.id}>
            {team.name}
          </Select.Option>
        ))}
      </Select.OptGroup>
      <Select.OptGroup label="NFL">
        {nflTeams.map((team) => (
          <Select.Option value={team.id} key={team.id}>
            {team.name}
          </Select.Option>
        ))}
      </Select.OptGroup>
    </Select>
  );

  return (
    <div className="game-form">
      <div className="game-form__header">
        <h2 className="game-form__title">{`Game ${num}`}</h2>
        <div className="game-form__remove">
          <Button type="link" onClick={() => onRemove(id)}>
            <CloseSquareFilled />
          </Button>
        </div>
      </div>
      <div className="game-form__team-box">
        {getTeamSelect(teamA, (team) => {
          setTeamA(team);
        })}
        <div className="game-form__spread-box">
          <div className="game-form__spread-label">{"Spread"}</div>
          <InputNumber
            min={-75}
            max={75}
            step={0.5}
            disabled={!teamA}
            defaultValue={spreadA || undefined}
            onChange={(num) => setSpreadA(Number(num))}
          />
        </div>
      </div>
      <div className="game-form__team-box">
        {getTeamSelect(teamB, (team) => {
          setTeamB(team);
        })}
        <div className="game-form__spread-box">
          <div className="game-form__spread-label">{"Spread"}</div>
          <InputNumber
            min={-75}
            max={75}
            step={0.5}
            disabled={!teamA}
            defaultValue={spreadB || undefined}
            onChange={(num) => setSpreadB(Number(num))}
          />
        </div>
      </div>
      {sameTeamError && (
        <div className="game-form__error-label">
          <WarningFilled className="game-form__error-icon" />
          {"Both teams are the same"}
        </div>
      )}
      {diffSportError && (
        <div className="game-form__error-label">
          <WarningFilled className="game-form__error-icon" />
          {"One team is college and the other is Pro"}
        </div>
      )}
      {teamAExists && (
        <div className="game-form__error-label">
          <WarningFilled className="game-form__error-icon" />
          {`${teamAExists} is already chosen for another game`}
        </div>
      )}
      {teamBExists && (
        <div className="game-form__error-label">
          <WarningFilled className="game-form__error-icon" />
          {`${teamBExists} is already chosen for another game`}
        </div>
      )}
      {teamA &&
        teamB &&
        spreadA !== null &&
        spreadB !== null &&
        !sameTeamError &&
        !diffSportError &&
        !teamAExists &&
        !teamBExists && (
          <div className="game-form__team-box">
            {"Nick's Pick"}
            <div className="game-form__winner-box">
              <Checkbox
                disabled={!teamA}
                checked={teamA.id === nicksPick}
                onChange={() => setNicksPick(teamA.id)}
              >
                {teamA.name}
              </Checkbox>
            </div>
            <div className="game-form__winner-box">
              <Checkbox
                disabled={!teamB}
                checked={teamB.id === nicksPick}
                onChange={() => setNicksPick(teamB.id)}
              >
                {teamB.name}
              </Checkbox>
            </div>
          </div>
        )}
    </div>
  );
};

export const saveGamesStateToStorage = (state: GamesState) => {
  try {
    window.localStorage.setItem("new_schedule", JSON.stringify(state));
    window.localStorage.setItem("new_schedule_time", Date.now().toString());
  } catch {}
};

export const clearGamesStateFromStorage = () => {
  try {
    window.localStorage.removeItem("new_schedule");
    window.localStorage.removeItem("new_schedule_time");
  } catch {}
};

export const getGamesStateFromStorage = (): GamesState => {
  const defaultState = {
    games: [
      {
        id: uuidv4(),
        game: null,
      },
    ],
  };

  try {
    const lastTiemstamp = +window.localStorage.getItem("new_schedule_time")!;
    if (Date.now() - lastTiemstamp > 48 * 60 * 60 * 1000) {
      return defaultState;
    }

    return JSON.parse(
      window.localStorage.getItem("new_schedule")!
    ) as GamesState;
  } catch {}

  return defaultState;
};
