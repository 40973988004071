import { Header } from "./ui/header";
import React from "react";
import "./App.css";
import { AuthContextManager } from "./auth/context";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { AdminPage } from "./pages/admin";
import { PickPage } from "./pages/pick";
import { MyPicksPage } from "./pages/my-picks";
import { Footer } from "./ui/footer";
import { StatsPage } from "./pages/stats";
import { LeaderboardsPage } from "./pages/leaderboards";

export const App: React.FC = () => {
  return (
    <>
      <div className="app-header">
        <Header />
      </div>
      <div className="app-content">
        <Switch>
          <Route path="/admin">
            <AdminPage />
          </Route>
          <Route path="/leaderboard">
            <LeaderboardsPage />
          </Route>
          <Route path="/stats">
            <StatsPage />
          </Route>
          <Route path="/mypicks">
            <MyPicksPage />
          </Route>
          <Route path="*">
            <PickPage />
          </Route>
        </Switch>
      </div>
      <div className="app-footer">
        <Footer />
      </div>
    </>
  );
};

export const AppContainer: React.FC = () => {
  return (
    <BrowserRouter>
      <AuthContextManager>
        <App />
      </AuthContextManager>
    </BrowserRouter>
  );
};
