import { useContext, useEffect, useReducer } from "react";
import { getTeams } from ".";
import { authContext } from "../auth/context";
import { Team } from "./models";

interface UseTeamsState {
  cfbTeams: Team[] | null;
  nflTeams: Team[] | null;
  loading: boolean;
}

interface UseTeamsLoadedAction {
  cfbTeams: Team[] | null;
  nflTeams: Team[] | null;
}

export const useTeams = () => {
  const { creds } = useContext(authContext);
  const accessToken = creds?.accessToken;

  const [state, dispatch] = useReducer(
    (_: UseTeamsState, action: UseTeamsLoadedAction) => {
      return {
        loading: false,
        cfbTeams: action.cfbTeams,
        nflTeams: action.nflTeams,
      };
    },
    {
      loading: true,
      cfbTeams: null,
      nflTeams: null,
    }
  );

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    getTeamsAsync(accessToken, (teams) => dispatch(teams));
  }, [accessToken]);

  return state;
};

const getTeamsAsync = async (
  accessToken: string,
  onTeamsLoaded: (ret: {
    cfbTeams: Team[] | null;
    nflTeams: Team[] | null;
  }) => void
) => {
  const teams = await getTeams(accessToken);

  if (!teams) {
    onTeamsLoaded({ cfbTeams: null, nflTeams: null });
    return;
  }

  const nflTeams = teams
    .filter((t) => t.sport === "nfl")
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  const cfbTeams = teams
    .filter((t) => t.sport === "cfb")
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  onTeamsLoaded({ cfbTeams, nflTeams });
};
