import { stringify } from "query-string";
import AWS, { CognitoIdentity } from "aws-sdk";
// import { v4 as uuidv4 } from "uuid";

export const CLIENT_ID = "8qdv5de0pfpvjuk9pb4jaku3q";
export const SIGN_IN_URI = `https://nickspicks.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=${CLIENT_ID}&redirect_uri=https://nickspicks.net`;

interface AuthCredentialsResponse {
  id_token: string;
  access_token: string;
  refresh_token?: string;
}

interface AuthCredentialsIdToken {
  name: string;
  email: string;
  "cognito:groups"?: string[];
  "cognito:preferred_role"?: string;
}

export interface AuthCredentials {
  accessToken: string;
  refreshToken?: string;
  name: string;
  email: string;
  isAdmin: boolean;
  adminRole?: string;
}

export const getAuthCredentialsFromCode = async (
  code: string,
  onAuthCredsSuccess: (creds: AuthCredentials) => void,
  onAuthCredsError: (err: Error) => void
) => {
  try {
    const path = window.location.pathname;
    const redirect_uri = `https://nickspicks.net${path !== "/" ? path : ""}`;

    const response = await fetch(
      "https://nickspicks.auth.us-west-2.amazoncognito.com/oauth2/token",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: stringify({
          grant_type: "authorization_code",
          client_id: CLIENT_ID,
          redirect_uri,
          code,
        }),
      }
    );

    await onCredentialsResponse(response, onAuthCredsSuccess);
  } catch (err) {
    console.error("Failed to validate credentials from Cognito code", err);
    onAuthCredsError(err);
  }
};

export const refreshAuthCredentialsFromToken = async (
  refreshToken: string,
  onAuthCredsSuccess: (creds: AuthCredentials) => void,
  onAuthCredsError: (err: Error) => void
) => {
  try {
    const response = await fetch(
      "https://nickspicks.auth.us-west-2.amazoncognito.com/oauth2/token",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: stringify({
          grant_type: "refresh_token",
          client_id: CLIENT_ID,
          redirect_uri: "https://nickspicks.net",
          refresh_token: refreshToken,
        }),
      }
    );

    await onCredentialsResponse(response, onAuthCredsSuccess);
  } catch (err) {
    console.error("Failed to validate credentials from Cognito code", err);
    onAuthCredsError(err);
  }
};

const onCredentialsResponse = async (
  response: Response,
  onAuthCredsSuccess: (creds: AuthCredentials) => void
) => {
  const json = (await response.json()) as AuthCredentialsResponse;
  const idToken = JSON.parse(
    atob(json.id_token.split(".")[1])
  ) as AuthCredentialsIdToken;

  const authCreds: AuthCredentials = {
    accessToken: json.id_token,
    refreshToken: json.refresh_token,
    name: idToken.name,
    email: idToken.email,
    isAdmin: idToken["cognito:groups"]?.includes("admin") || false,
    adminRole: idToken["cognito:preferred_role"],
  };

  onAuthCredsSuccess(authCreds);
};

export const navigateToSignInUrl = () => {
  const path = window.location.pathname;
  const authUri = path !== "/" ? `${SIGN_IN_URI}${path}` : SIGN_IN_URI;
  window.location.assign(authUri);
};

export const getAdminCredentials = async (
  accessToken: string
): Promise<CognitoIdentity.Credentials> => {
  return new Promise((resolve, reject) => {
    AWS.config.region = "us-west-2";
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: "us-west-2:57c24655-8b35-43cb-822c-6bf50ca5a94a",
    });

    const cognito = new AWS.CognitoIdentity();
    cognito.getId(
      {
        IdentityPoolId: "us-west-2:57c24655-8b35-43cb-822c-6bf50ca5a94a",
        Logins: {
          "cognito-idp.us-west-2.amazonaws.com/us-west-2_6fudFi3EZ": accessToken,
        },
      },
      (_, data) => {
        if (!data.IdentityId) {
          reject("No Identity ID");
          return;
        }

        cognito.getCredentialsForIdentity(
          {
            IdentityId: data.IdentityId,
            Logins: {
              "cognito-idp.us-west-2.amazonaws.com/us-west-2_6fudFi3EZ": accessToken,
            },
          },
          (err, data) => {
            if (err || !data.Credentials) {
              reject(err);
            } else {
              resolve(data.Credentials);
            }
          }
        );
      }
    );
  });
};
