import { Alert, Button } from "antd";
import * as React from "react";
import { pickGameWinners } from "../../api";
import { GameWinners, Schedule } from "../../api/models";
import { useSchedule } from "../../api/use-schedule";
import { PageHeader } from "../../ui/page-header";
import { SelectionMode, Ticket } from "../../ui/ticket";
import "./pick-winners.css";

interface Props {
  schedule: Schedule;
  updateWithGameWinners: ReturnType<
    typeof useSchedule
  >["updateWithGameWinners"];
  accessToken: string;
}

export const PickWinners: React.FC<Props> = ({
  schedule,
  accessToken,
  updateWithGameWinners,
}) => {
  const [hasError, setHasError] = React.useState(false);
  const [isPicking, setIsPicking] = React.useState(false);
  const [currentPicks, setCurrentPicks] = React.useState<
    Record<string, string>
  >({});

  const unlocked =
    "Looks like the week isn't locked yet. Once the week is locked, you'll be able to choose winners for each game. If it should be locked, ping Ben.";

  const readyForPicking =
    "Choose game winners when they happen - make sure to account for the spread! You can save as many or as few as you want, just check the boxes and click Save Winners at the bottom. Once all winners are saved, you'll be able to create a new week.";

  const pickButton = (
    <div className="pick-winners__submit-picks">
      <Button
        type="primary"
        size={"large"}
        disabled={isPicking}
        onClick={() => {
          setIsPicking(true);
          submitPicks({
            accessToken,
            picks: currentPicks,
            scheduleID: schedule?.id || "",
            onSuccess: (winners) => {
              setHasError(false);
              updateWithGameWinners(winners);
              setIsPicking(false);
              setCurrentPicks({});
            },
            onFailure: () => {
              setHasError(true);
              setIsPicking(false);
            },
          });
        }}
      >
        {"Save Winners!"}
      </Button>
    </div>
  );

  return (
    <div className="pick-winners">
      <PageHeader title={"Admin - Pick Weekly Winners"}>
        <>
          {!schedule.locked && unlocked}
          {schedule.locked && readyForPicking}
        </>
      </PageHeader>
      <div className="pick-winners__tickets">
        {schedule &&
          schedule.locked &&
          schedule.games.map((game) => (
            <div className="pick-winners__ticket" key={game.id}>
              <Ticket
                game={game}
                selectionMode={
                  game.winningTeamID
                    ? SelectionMode.None
                    : SelectionMode.PickWinner
                }
                enabledSelection={!schedule.locked}
                onSelect={(gameID, teamID) =>
                  setCurrentPicks({
                    ...currentPicks,
                    [gameID]: teamID,
                  })
                }
                onUnselect={(gameID) => {
                  const next = { ...currentPicks };
                  delete next[gameID];
                  setCurrentPicks(next);
                }}
              />
            </div>
          ))}
      </div>
      {hasError && !isPicking && (
        <div className="pick-winners__error">
          <Alert
            message={"Failed to save game winners!"}
            description={"Refresh and try again."}
            type="error"
          />
        </div>
      )}
      {!!Object.keys(currentPicks).length && (
        <div className="pick-winners__submit-picks">{pickButton}</div>
      )}
    </div>
  );
};

interface SubmitPicksOpts {
  scheduleID: string;
  picks: Record<string, string>;
  accessToken?: string;
  onSuccess: (guestPick: GameWinners) => void;
  onFailure: () => void;
}

const submitPicks = async (opts: SubmitPicksOpts) => {
  if (!opts.accessToken) {
    return;
  }

  const winners: GameWinners = {
    scheduleID: opts.scheduleID,
    games: opts.picks,
  };

  const resp = await pickGameWinners(winners, opts.accessToken);

  if (resp?.success) {
    opts.onSuccess(winners);
  } else {
    opts.onFailure();
  }
};
