import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import * as React from "react";
import { Game, PUSH_NO_WINNER } from "../../api/models";
import "./index.css";

export enum SelectionMode {
  None = "None",
  Guest = "Guest",
  PickWinner = "PickWinner",
}

interface Props {
  game: Game;
  enabledSelection: boolean;
  selectionMode: SelectionMode;
  onSelect: (gameID: string, teamID: string) => void;
  onUnselect: (gameID: string) => void;
}

export const Ticket: React.FC<Props> = ({
  game,
  selectionMode,
  enabledSelection,
  onSelect,
  onUnselect,
}) => {
  const [checkedID, setCheckedID] = React.useState<string | null>(null);
  // const [transform] = React.useState(() => {
  //   const deg = Math.floor(Math.random() * 4);
  //   const pos = deg % 2 ? 1 : -1;
  //   return `rotate(${pos * deg}deg)`;
  // });

  return (
    <div className="ticket">
      <div className="ticket__inner">
        <div className="ticket__teams">
          <div className="ticket__team-container">
            <div
              className="ticket__team-name"
              style={{ color: `#${game.teamA.color}` }}
            >
              {game.teamA.name}
            </div>
            <div className="ticket__team-spread">
              {formatSpread(game.spread.a)}
            </div>
          </div>
          <div className="ticket__team-vs">VS</div>
          <div className="ticket__team-container">
            <div
              className="ticket__team-name"
              style={{ color: `#${game.teamB.color}` }}
            >
              {game.teamB.name}
            </div>
            <div className="ticket__team-spread">
              {formatSpread(game.spread.b)}
            </div>
          </div>
        </div>
        {game.nicksPickTeamID &&
          getCheckedSection({
            title: "NICK'S PICK",
            isTeamA: game.nicksPickTeamID === game.teamA.id,
          })}
        {game.guestPickTeamID &&
          selectionMode !== SelectionMode.PickWinner &&
          getCheckedSection({
            title: "YOUR PICK",
            isTeamA: game.guestPickTeamID === game.teamA.id,
          })}
        {game.winningTeamID &&
          game.winningTeamID === PUSH_NO_WINNER &&
          getPushWinnerSection()}
        {game.winningTeamID &&
          game.winningTeamID !== PUSH_NO_WINNER &&
          getCheckedSection({
            title: "THE WINNER",
            isTeamA: game.winningTeamID === game.teamA.id,
          })}
        {selectionMode === SelectionMode.Guest &&
          getSelectableSection({
            title: enabledSelection
              ? "SIGN IN TO PICK A WINNER!"
              : game.guestPickTeamID
              ? "UPDATE YOUR PICK"
              : "PICK A WINNER",
            disabled: enabledSelection,
            game,
            checkedID,
            onChange: (teamID: string) => {
              onSelect(game.id, teamID);
              setCheckedID(teamID);
            },
          })}
        {selectionMode === SelectionMode.PickWinner &&
          getSelectableSection({
            title: "WHO REALLY WON?",
            allowPush: true,
            disabled: enabledSelection,
            game,
            checkedID,
            onChange: (teamID: string) => {
              if (checkedID === teamID) {
                setCheckedID(null);
                onUnselect(game.id);
              } else {
                onSelect(game.id, teamID);
                setCheckedID(teamID);
              }
            },
          })}
      </div>
    </div>
  );
};

const getPushWinnerSection = () => (
  <div className="ticket__pick-container">
    <div className="ticket__pick-header">{"THE WINNER"}</div>
    <div className="ticket__pick-push">{"PUSH!"}</div>
  </div>
);

interface CheckedSectionOpts {
  title: string;
  isTeamA: boolean;
}

const getCheckedSection = ({ title, isTeamA }: CheckedSectionOpts) => (
  <div className="ticket__pick-container">
    <div className="ticket__pick-header">{title}</div>
    <div className="ticket__pick-checkboxes">
      <div className="ticket__pick-checkbox ticket__pick-checkbox-border-right">
        {isTeamA ? (
          <CheckOutlined width="64px" height="64px" />
        ) : (
          <CloseOutlined
            width="64px"
            height="64px"
            className="ticket__pick-disabled"
          />
        )}
      </div>
      <div className="ticket__pick-checkbox">
        {!isTeamA ? (
          <CheckOutlined width="64px" height="64px" />
        ) : (
          <CloseOutlined
            width="64px"
            height="64px"
            className="ticket__pick-disabled"
          />
        )}
      </div>
    </div>
  </div>
);

interface SelectableSectionOpts {
  title: string;
  disabled: boolean;
  allowPush?: boolean;
  game: Game;
  checkedID: string | null;
  onChange: (id: string) => void;
}

const getSelectableSection = (opts: SelectableSectionOpts) => (
  <div className="ticket__pick-container">
    <div className="ticket__pick-header">{opts.title}</div>
    <div className="ticket__pick-checkboxes">
      <div className="ticket__pick-checkbox ticket__pick-checkbox-border-right">
        <Checkbox
          checked={opts.checkedID === opts.game.teamA.id}
          disabled={opts.disabled}
          onChange={() => opts.onChange(opts.game.teamA.id)}
        />
      </div>
      {opts.allowPush && (
        <div className="ticket__pick-checkbox ticket__pick-checkbox-border-right">
          <Checkbox
            checked={opts.checkedID === PUSH_NO_WINNER}
            disabled={opts.disabled}
            onChange={() => opts.onChange(PUSH_NO_WINNER)}
          />
          <span className="ticket__pick-checkbox-push">{"PUSH"}</span>
        </div>
      )}
      <div className="ticket__pick-checkbox">
        <Checkbox
          checked={opts.checkedID === opts.game.teamB.id}
          disabled={opts.disabled}
          onChange={() => opts.onChange(opts.game.teamB.id)}
        />
      </div>
    </div>
  </div>
);

const formatSpread = (spread: number) => {
  const out = spread > 0 ? `+${spread}` : spread;
  return `( ${out} )`;
};
