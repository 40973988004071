import { Spin, Table, Tabs, Tag } from "antd";
import Column from "antd/lib/table/Column";
import { TabPane } from "rc-tabs";
import * as React from "react";
import { LeaderboardRow } from "../../api/models";
import { PageHeader } from "../../ui/page-header";
import { CrownFilled } from "@ant-design/icons";
import "./index.css";
import { useLeaderboards } from "../../api/use-leaderboards";

export const Leaderboard: React.FC<{
  loading: boolean;
  rows: LeaderboardRow[] | null;
}> = ({ loading, rows }) => {
  if (loading) {
    return (
      <div className="leaderboard">
        <Spin size={"large"} />
      </div>
    );
  } else if (!rows) {
    const text =
      "Once Nick's picks are in you'll be able to see this week's top scorers! You can check previous weeks or the entire season too.";

    return (
      <div className="leaderboard">
        <div className="leaderboard__error">{text}</div>
      </div>
    );
  }

  return (
    <Table
      size="middle"
      dataSource={rows}
      bordered
      rowClassName={(row: LeaderboardRow) =>
        row.isNick
          ? "leaderboard__row-nick"
          : row.isSelf
          ? "leaderboard__row-self"
          : ""
      }
    >
      <Column
        title="Name"
        dataIndex="name"
        key="name"
        render={(name, row: LeaderboardRow) => {
          return (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="leaderboard__tags-name">{name}</div>
              {row.isNick && (
                <div className="leaderboard__tags-tag">
                  <Tag color="red" key={"is_nick"}>
                    {"Nick!"}
                  </Tag>
                </div>
              )}
              {row.isMoes && (
                <div className="leaderboard__tags-tag">
                  <Tag color="red" key={"is_moes"}>
                    {"Moe's BBQ"}
                  </Tag>
                </div>
              )}
              {row.isSelf && (
                <div className="leaderboard__tags-tag">
                  <Tag color="red" key={"is_moes"}>
                    {"You!"}
                  </Tag>
                </div>
              )}
              {row.numEntries > 0 && (
                <div className="leaderboard__tags-tag">
                  <Tag color="red" key={"entries"} icon={<CrownFilled />}>
                    {row.numEntries}
                  </Tag>
                </div>
              )}
              <div></div>
            </div>
          );
        }}
      />
      <Column
        title="Picks"
        render={(_, row: LeaderboardRow) => {
          return (
            <div className="leaderboard__tags-picks">
              <span className="leaderboard__tags-picks__highlight">{`${row.winningPicks}`}</span>
              {` / ${row.totalPicks} `}
              {pct(row)}
            </div>
          );
        }}
      />
    </Table>
  );
};

const pct = (row: LeaderboardRow) => {
  if (!row.totalPicks) {
    return null;
  }

  return (
    <p className="leaderboard__tags-picks__pct">{` (${(
      (row.winningPicks / row.totalPicks) *
      100
    ).toFixed(2)}%)`}</p>
  );
};

export const LeaderboardsPage: React.FC = () => {
  const { leaderboards, loading } = useLeaderboards();

  return (
    <div className="leaderboard-page">
      <PageHeader title={"Leaderboard"}>
        Compare your stats to Nick's this week or all season long.
        <div className="leaderboard-header__legend">
          <Tag color="red" key={"entries"} icon={<CrownFilled />}>
            {1}
          </Tag>
          {
            "indicates how many weeks a user has outpicked Nick, and how many entries into the grand prize drawing they have."
          }
        </div>
      </PageHeader>
      <Tabs defaultActiveKey={"0"}>
        <TabPane tab="This Week" key="0">
          <Leaderboard
            loading={loading}
            rows={leaderboards?.currentWeek || null}
          />
        </TabPane>
        <TabPane tab="Season" key="1">
          <Leaderboard loading={loading} rows={leaderboards?.overall || null} />
        </TabPane>
        {!loading &&
          leaderboards?.completedWeeks &&
          leaderboards?.completedWeeks.length > 0 &&
          leaderboards.completedWeeks.map((rows, i) => (
            <TabPane tab={`Week ${i + 1}`} key={`w-${i}`}>
              <Leaderboard loading={false} rows={rows} />
            </TabPane>
          ))}
      </Tabs>
    </div>
  );
};
