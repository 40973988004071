import { Button } from "antd";
import * as React from "react";
import { Schedule, ScheduleWinners } from "../../api/models";
import dl from "downloadjs";

interface Props {
  schedule: Schedule;
  picksForSchedule: number;
  winningPicks: ScheduleWinners | null;
}

export const StatsPanel: React.FC<Props> = ({
  schedule,
  picksForSchedule,
  winningPicks,
}) => {
  const [csv] = React.useState<string | null>(() => {
    if (!winningPicks || !winningPicks.winningGuests.length) {
      return null;
    }

    const columns = "name,email,emailed,address,shirt sent";
    const winners = winningPicks.winningGuests.reduce((winningCsv, guest) => {
      return `${winningCsv}${guest.name},${guest.email},,,\n`;
    }, "");

    return `${columns}\n${winners}`;
  });

  const nicksPicksTotal = schedule.games.filter(
    (game) => game.winningTeamID === game.nicksPickTeamID
  ).length;

  const completedSchedule = winningPicks && (
    <>
      <p>
        Nick's Picks:{" "}
        <span className="stats-panel__picks-count">{`${nicksPicksTotal}/${
          schedule.games.length
        } - ${((nicksPicksTotal / schedule.games.length) * 100).toFixed(
          1
        )}%`}</span>
      </p>
      <p>
        Winning picks:{" "}
        <span className="stats-panel__picks-count">
          {winningPicks.winningGuests.length}
        </span>
      </p>
    </>
  );

  const activeSchedule = (
    <p className="stats-panel__active-week">
      This week is still live! You'll be able to download winner information
      when it's completed.
    </p>
  );

  const download = csv && (
    <div className="stats-panel__download">
      <Button
        type="primary"
        size={"large"}
        onClick={async () => {
          if (!!navigator.share) {
            try {
              await navigator.share({
                title: `NicksPicks Winners - ${new Date(
                  schedule.createdAt
                ).toDateString()}`,
                // @ts-ignore
                files: [
                  new File([csv], `nickspicks_winners_${schedule.id}.csv`, {
                    type: "text/csv",
                  }),
                ],
              });
            } catch (err) {
              console.warn("Failed to share file", err);
            }
          } else {
            dl(csv, `nickspicks_winners_${schedule.id}.csv`, "text/csv");
          }
        }}
      >
        {"Download Winners Spreadsheet"}
      </Button>
    </div>
  );

  return (
    <div className="stats-panel">
      <p>
        Total picks:{" "}
        <span className="stats-panel__picks-count">{picksForSchedule}</span>
      </p>
      {schedule.completed && completedSchedule}
      {!schedule.completed && activeSchedule}
      {download}
    </div>
  );
};
