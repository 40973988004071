import { Alert, Button, Checkbox } from "antd";
import * as React from "react";
import { lockWeek } from "../../api";
import { useSchedule } from "../../api/use-schedule";
import { PageHeader } from "../../ui/page-header";
import "./lock-week.css";

interface Props {
  markWeekLocked: ReturnType<typeof useSchedule>["markWeekLocked"];
  accessToken: string;
}

export const LockWeek: React.FC<Props> = ({ accessToken, markWeekLocked }) => {
  const [hasError, setHasError] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [readyToSave, setReadyToSave] = React.useState(false);

  const lockButton = (
    <div className="lock-week__submit">
      <Button
        type="primary"
        size={"large"}
        disabled={isSaving || !readyToSave}
        onClick={() => {
          setIsSaving(true);
          lockWeekAsync({
            accessToken,
            onSuccess: () => {
              setHasError(false);
              setIsSaving(false);
              markWeekLocked();
            },
            onFailure: () => {
              setHasError(true);
              setIsSaving(false);
            },
          });
        }}
      >
        {"Lock Week"}
      </Button>
    </div>
  );

  return (
    <div className="lock-week">
      <PageHeader title={"Admin - Live Week"}>
        The week is live and is set to auto-lock at 11:00am CDT on Saturday. If
        there's a problem and you really need to lock the week early, press the
        button below.
      </PageHeader>
      {hasError && !isSaving && (
        <div className="lock-week__error">
          <Alert
            message={"Failed to lock week."}
            description={"Refresh and try again."}
            type="error"
          />
        </div>
      )}
      <div className="lock-week__ready">
        <Checkbox
          checked={readyToSave}
          onChange={() => setReadyToSave(!readyToSave)}
        >
          Are you sure you want to lock the week early?
        </Checkbox>
      </div>
      {lockButton}
    </div>
  );
};

interface LockWeekOpts {
  accessToken: string;
  onSuccess: () => void;
  onFailure: () => void;
}

const lockWeekAsync = async (opts: LockWeekOpts) => {
  const resp = await lockWeek(opts.accessToken);

  if (resp?.success) {
    opts.onSuccess();
  } else {
    opts.onFailure();
  }
};
