import * as React from "react";
import { Button } from "antd";
import { navigateToSignInUrl } from "../../auth";

export const AuthButton: React.FC = () => {
  return (
    <Button type="primary" size={"large"} onClick={navigateToSignInUrl}>
      {"Sign In"}
    </Button>
  );
};
