export interface Team {
  logo: string;
  id: string;
  name: string;
  color: string;
  sport: string;
}

export interface Schedule {
  id: string;
  locked: boolean;
  completed: boolean;
  createdAt: number;
  games: Game[];
}

export interface Game {
  teamA: Team;
  teamB: Team;
  spread: {
    a: number;
    b: number;
  };
  nicksPickTeamID?: string;
  guestPickTeamID?: string;
  winningTeamID?: string;
  id: string;
}

export interface GuestPick {
  scheduleID: string;
  games: Record<string, string>;
  email?: string;
  userID?: string;
}

export interface GameWinners {
  scheduleID: string;
  games: Record<string, string>;
}

export interface WinningGuest {
  name: string;
  email: string;
}

export interface ScheduleWinners {
  scheduleID: string;
  winningGuests: WinningGuest[];
}

export interface AdminPortalInfo {
  schedule: Schedule | null;
  previousSchedules: Schedule[];
  totalPicksForCurrentSchedule: number;
  allWinningPicks: ScheduleWinners[];
}

export interface AdminStats {
  schedules: Schedule[];
  picksForSchedule: Record<string, number>;
  winningPicks: Record<string, ScheduleWinners>;
}

export const PUSH_NO_WINNER = "PUSH_NO_WINNER";

export interface LeaderboardRow {
  name: string;
  winningPicks: number;
  totalPicks: number;
  isNick: boolean;
  isMoes: boolean;
  isSelf: boolean;
  numEntries: number;
}

export type AllLeaderboards = {
  currentWeek: LeaderboardRow[] | null;
  completedWeeks: Array<LeaderboardRow[]>;
  overall: LeaderboardRow[];
  error: false;
};

export type LeaderboardsAPIResponse = AllLeaderboards | { error: string };
