import { Alert, Button } from "antd";
import * as React from "react";
import { completeWeek } from "../../api";
import { Schedule } from "../../api/models";
import { useSchedule } from "../../api/use-schedule";
import { PageHeader } from "../../ui/page-header";
import "./calculate-winners.css";

interface Props {
  schedule: Schedule;
  accessToken: string;
  markWeekCompleted: ReturnType<typeof useSchedule>["markWeekCompleted"];
}

export const CalculateWinners: React.FC<Props> = ({
  schedule,
  accessToken,
  markWeekCompleted,
}) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  const completeWeekButton = (
    <div className="calculate-winners__button">
      <Button
        type="primary"
        size={"large"}
        disabled={isSaving}
        onClick={() => {
          setIsSaving(true);
          completeWeekAsync({
            accessToken,
            scheduleID: schedule.id,
            onSuccess: () => {
              setHasError(false);
              setIsSaving(false);
              markWeekCompleted();
            },
            onFailure: () => {
              setHasError(true);
              setIsSaving(false);
            },
          });
        }}
      >
        {"Calculate Winning Picks!"}
      </Button>
    </div>
  );

  return (
    <div className="calculate-winners">
      <PageHeader title={"Admin - Calculate Winning Picks"}>
        <p style={{ marginBottom: "1rem" }}>
          {
            "The scores are in and the week is complete, ready to see who outpicked Nick?"
          }
        </p>
        <p>
          {
            "Clicking the button will calculate winners, email the admins the winners, and totally complete the week. Once you've calculated winners, the previous week's games will no longer display, and you'll be able to create a new weekly schedule."
          }
        </p>
      </PageHeader>
      {hasError && !isSaving && (
        <div className="calculate-winners__error">
          <Alert
            message={"Failed to calculate winners... probably a server glitch."}
            description={"Refresh and try again."}
            type="error"
          />
        </div>
      )}
      {completeWeekButton}
    </div>
  );
};

interface CompleteWeekOpts {
  scheduleID: string;
  accessToken: string;
  onSuccess: () => void;
  onFailure: () => void;
}

const completeWeekAsync = async (opts: CompleteWeekOpts) => {
  const resp = await completeWeek(opts.scheduleID, opts.accessToken);

  if (resp?.success) {
    opts.onSuccess();
  } else {
    opts.onFailure();
  }
};
