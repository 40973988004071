import { useContext, useEffect, useReducer } from "react";
import { getLeaderboardsAsync } from ".";
import { authContext } from "../auth/context";
import { AllLeaderboards } from "./models";

interface UseLeaderboardState {
  leaderboards: AllLeaderboards | null;
}

interface UseLeaderboardLoadedAction {
  response: AllLeaderboards | null;
}

let cachedApiResponse: AllLeaderboards | null = null;

export const useLeaderboards = () => {
  const { creds } = useContext(authContext);
  const accessToken = creds?.accessToken;

  const [state, dispatch] = useReducer(
    (_: UseLeaderboardState, action: UseLeaderboardLoadedAction) => {
      if (action.response?.error) {
        return { loading: false, leaderboards: null };
      }
      return { loading: false, leaderboards: action.response };
    },
    {
      loading: true,
      leaderboards: null,
    }
  );

  useEffect(() => {
    if (cachedApiResponse) {
      dispatch({ response: cachedApiResponse });
      return;
    }

    getLeaderboardsAsync((response) => {
      if (response && !response.error) {
        cachedApiResponse = response as AllLeaderboards;
      }
      dispatch({ response: response as AllLeaderboards | null });
    }, accessToken);
  }, [accessToken]);

  return state;
};
