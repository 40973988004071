import { ThunderboltFilled } from "@ant-design/icons";
import * as React from "react";
import { Schedule } from "../../api/models";
import "./live-week-scoreboard.css";

interface LiveWeekStats {
  nicksPicks: number;
  guestPicks?: number;
  gamesCompleted: number;
  gamesRemaining: number;
}

interface Props {
  schedule: Schedule | null;
}

export const LiveWeekScoreboard: React.FC<Props> = ({ schedule }) => {
  const liveStats = schedule?.games.reduce(
    (stats, game) => {
      if (game.winningTeamID) {
        const nickPickedCorrectly = game.winningTeamID === game.nicksPickTeamID;

        stats.gamesCompleted++;
        stats.nicksPicks = stats.nicksPicks + (nickPickedCorrectly ? 1 : 0);

        if (game.guestPickTeamID) {
          const guestPickedCorrectly =
            game.winningTeamID === game.guestPickTeamID;
          stats.guestPicks =
            (stats.guestPicks || 0) + (guestPickedCorrectly ? 1 : 0);
        }
      } else {
        stats.gamesRemaining++;
      }

      return stats;
    },
    {
      nicksPicks: 0,
      gamesCompleted: 0,
      gamesRemaining: 0,
      guestPicks: schedule?.games[0].guestPickTeamID ? 0 : undefined,
    } as LiveWeekStats
  );

  if (!liveStats) {
    return null;
  }

  return (
    <div className="scoreboard">
      <div className="scoreboard__inner">
        <div className="scoreboard__header">
          Live Scoreboard
          <span className="scoreboard__header-icon">
            <ThunderboltFilled />
          </span>
        </div>
        <div className="scoreboard__stats-label">
          Nick
          <span className="scoreboard__stats-label-bold">
            {liveStats.nicksPicks}
          </span>
          {!!liveStats.gamesCompleted && (
            <span className="scoreboard__stats-label-percent">
              {`/${liveStats.gamesCompleted} (${(
                (liveStats.nicksPicks / liveStats.gamesCompleted) *
                100
              ).toFixed(1)} %)`}
            </span>
          )}
        </div>
        {liveStats.guestPicks !== undefined && (
          <div className="scoreboard__stats-label">
            You
            <span className="scoreboard__stats-label-bold">
              {liveStats.guestPicks}
            </span>
            {!!liveStats.gamesCompleted && (
              <span className="scoreboard__stats-label-percent">
                {`/${liveStats.gamesCompleted} (${(
                  (liveStats.guestPicks / liveStats.gamesCompleted) *
                  100
                ).toFixed(1)} %)`}
              </span>
            )}
          </div>
        )}
        <div className="scoreboard__stats-label">
          Games Remaining
          <span className="scoreboard__stats-label-bold">
            {liveStats.gamesRemaining}
          </span>
        </div>
      </div>
    </div>
  );
};
