import { Button, Divider, Image, Modal } from "antd";
import * as React from "react";
import "./index.css";
import logoPng from "../../assets/logo_new.png";
import { authContext } from "../../auth/context";
import { Link, useLocation } from "react-router-dom";

export const Header: React.FC = ({ children }) => {
  const { creds } = React.useContext(authContext);
  const location = useLocation();

  const blur = (event: React.MouseEvent) => {
    (event.currentTarget as HTMLButtonElement).blur();
  };

  return (
    <>
      <div className="header__nav">
        <Link to="/">
          <p className="header__nav-text">Nick's Picks</p>
        </Link>
        <div className="header__nav-spacer" />
        <div className="header__nav-buttons">
          {creds?.isAdmin && location.pathname !== "/admin" && (
            <Link to="/admin">
              <Button type="ghost" className="header-button" onClick={blur}>
                {"Admin"}
              </Button>
            </Link>
          )}
          {creds?.isAdmin && location.pathname !== "/stats" && (
            <Link to="/stats">
              <Button type="ghost" className="header-button" onClick={blur}>
                {"Stats"}
              </Button>
            </Link>
          )}
          {!location.pathname.startsWith("/leaderboard") && (
            <Link to="/leaderboard">
              <Button type="ghost" className="header-button" onClick={blur}>
                {"Leaderboard"}
              </Button>
            </Link>
          )}
          {location.pathname !== "/" && (
            <Link to="/">
              <Button type="ghost" className="header-button" onClick={blur}>
                {"Home"}
              </Button>
            </Link>
          )}
        </div>
      </div>
      <header className="header">
        <Link to="/">
          <Image src={logoPng} height="300px" width="300px" preview={false} />
        </Link>
        <p>
          Outpick Nick. Win some SEC championship tix.{" "}
          <strong>If you can.</strong>
        </p>
        <p>
          <Button
            type="link"
            onClick={() => {
              Modal.info({
                title: "What is Nick's Picks?",
                content: (
                  <div className="header-modal">
                    <p>
                      {
                        "Every week, Nick picks winners from a slew of CFB and NFL games. Then he opens the doors for folks like you to pick your winners too. If you outpick Nick, you'll be entered into a raffle to win 2024 SEC Championship tickets for you and a friend."
                      }
                    </p>
                    <p style={{ margin: "1.5rem 0" }}>
                      <strong>Grand Prize</strong>
                    </p>
                    <p>
                      <ul>
                        <li>
                          2 tickets to the NCAA football SEC Championship Game.
                          The game will be held on Saturday December 7, 2024.
                        </li>

                      </ul>
                    </p>
                    <p>
                      <strong>Details</strong>
                    </p>
                    <p>
                      <ul>
                        <li>The contest begins on Monday, August 26, 2024.</li>
                        <li>
                          The contest ends on Sunday, December 1, 2024.
                        </li>
                        <li>
                          The contest winner will be announced on Monday December 2, 2024.
                        </li>
                        <li>
                          If you are the winner, we will contact you via the
                          email associated with your account. Please reply
                          promptly to ensure you can receive the tickets.
                        </li>
                        <li>The contest is totally free to play.</li>
                        <li>You are allowed one entry per week.</li>
                        <li>
                          Outpicking Nick means that you have picked more games
                          correctly than Nick did during the week.
                        </li>
                        <li>
                          Remember that you are picking against the spread, not
                          just winners!
                        </li>
                      </ul>
                    </p>
                    <div style={{ textAlign: "center" }}></div>
                  </div>
                ),
                cancelButtonProps: {
                  hidden: true,
                },
                okText: "Let's Pick!",
                icon: null,
              });
            }}
          >
            {"Read the rules!"}
          </Button>
        </p>
        <Divider />
      </header>
    </>
  );
};
