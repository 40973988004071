import { Image } from "antd";
import * as React from "react";
import "./index.css";
import logo from "../../assets/logo2.png";

export const Footer: React.FC = () => {
  return (
    <div className="footer">
      <a
        href="https://www.moesoriginalbbq.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image src={logo} width="300px" preview={false} className="footer__image"/>
      </a>
    </div>
  );
};
