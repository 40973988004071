import { LockOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import * as React from "react";
import "./index.css";

export enum PageHeaderIcon {
  Locked = "locked",
}

interface Props {
  title: string;
  icon?: PageHeaderIcon;
}

export const PageHeader: React.FC<Props> = (props) => {
  return (
    <div className="page-header">
      <div className="page-header__text">
        {props.title}
        {getIcon(props.icon)}
      </div>
      <div className="page-header__info">{props.children || null}</div>
      <Divider />
    </div>
  );
};

const getIcon = (icon?: PageHeaderIcon) => {
  if (!icon) {
    return null;
  }

  return (
    <span className="page-header__icon">
      {icon === PageHeaderIcon.Locked && <LockOutlined />}
    </span>
  );
};
