import { useContext, useEffect, useReducer } from "react";
import { getAdminStats } from ".";
import { authContext, AuthStatus } from "../auth/context";
import { AdminStats } from "./models";

interface AdminStatsState extends AdminStats {
  loading: boolean;
  error: boolean;
}

interface LoadedAction {
  type: "loaded";
  stats: AdminStats;
}

interface ErrorAction {
  type: "error";
}

type Action = LoadedAction | ErrorAction;

const getDefaultStats = (): AdminStats => ({
  schedules: [],
  picksForSchedule: {},
  winningPicks: {},
});

export const useAdminStats = () => {
  const { creds, status } = useContext(authContext);
  const accessToken = creds?.accessToken;

  const [state, dispatch] = useReducer(
    (state: AdminStatsState, action: Action) => {
      switch (action.type) {
        case "loaded":
          return {
            error: false,
            loading: false,
            ...action.stats,
          };
        case "error":
          return {
            ...state,
            loading: false,
            error: false,
          };
        default:
          return state;
      }
    },
    {
      loading: true,
      error: false,
      ...getDefaultStats(),
    }
  );

  useEffect(() => {
    if (!accessToken && status === AuthStatus.Unknown) {
      return;
    } else if (!accessToken) {
      dispatch({ type: "error" });
      return;
    }

    getAdminStatsAsync((stats) => {
      if (stats) {
        dispatch({ type: "loaded", stats });
      } else {
        dispatch({ type: "error" });
      }
    }, accessToken);
  }, [accessToken, status]);

  return state;
};

const getAdminStatsAsync = async (
  onLoad: (info: AdminStats | null) => void,
  accessToken: string
) => {
  const stats = await getAdminStats(accessToken);
  onLoad(stats);
};
